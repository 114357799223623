var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Shifts",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', {
    staticStyle: {
      "margin-bottom": "50px"
    }
  }, [_c('h1', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Es gibt aktuell "), _c('span', [_vm._v(" " + _vm._s(_vm.shiftCount) + " Schichten")])]), _c('h3', [_vm._v("Gesamtumsatz der angezeigten Schichten: " + _vm._s(_vm.shiftSum))]), _c('br'), _c('p', {
    staticStyle: {
      "margin": "20px 0 0",
      "font-size": "16px"
    }
  }, [_vm._v(" Gesamtzahl der Zuschläge: " + _vm._s(_vm.finalExtrasAmountCount.count) + " "), _c('br'), _vm._v(" Gesamsumme der Zuschläge: " + _vm._s(_vm.round2d(_vm.finalExtrasAmountCount.amount / 100).format()) + "€ ")])]), _c('Toolbar', [_c('div', [_c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "placeholder": "Fahrer",
      "selected": _vm.selectedEmployee,
      "items": _vm.drivers.filter(function (d) {
        return d.isVisible;
      })
    },
    on: {
      "onItemSelect": _vm.handleEmployeeSelect
    }
  }), _c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "selected": _vm.selectedCar,
      "items": _vm.cars,
      "placeholder": "Kennzeichen"
    },
    on: {
      "onItemSelect": _vm.handleCarSelect
    }
  }), _c('Datepicker', {
    attrs: {
      "startDate": _vm.selectedFrom,
      "endDate": _vm.selectedTo
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Button', {
    attrs: {
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleQuery
    }
  }, [_vm._v(" Anzeigen ")])], 1), _c('div', [_c('Download', {
    attrs: {
      "title": _vm.isMobile ? 'PDF' : 'Download PDF',
      "type": "pdf"
    },
    on: {
      "onDownload": _vm.handleDownload
    }
  })], 1)]), _c('Toolbar', [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "20px"
    }
  }, [_c('Checkbox', {
    attrs: {
      "size": "small",
      "keyName": "includeWrongTrips",
      "name": "includeWrongTrips",
      "value": _vm.includeWrongTrips,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onCheck": _vm.handleIncludeWrongTrips
    }
  }, [_vm._v(" Filter: Falschanmeldungen ")]), _c('Checkbox', {
    attrs: {
      "size": "small",
      "keyName": "showAllTrips",
      "name": "showAllTrips",
      "value": _vm.showAllTrips,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onCheck": _vm.handleShowAllTrips
    }
  }, [_vm._v(" Alle Fahrten anzeigen ")])], 1)]), _c('ShiftsTable', {
    attrs: {
      "shifts": _vm.filteredShifts || _vm.shifts,
      "showAllTrips": _vm.showAllTrips
    },
    on: {
      "onRowHover": _vm.handleRowHover,
      "onDriverClick": _vm.handleDriverClick,
      "onRowLeave": _vm.handleRowLeave,
      "onTripClick": _vm.handleTripClick,
      "onHeaderClick": _vm.handleHeaderClick
    }
  }), _c('Modal', {
    attrs: {
      "title": "Markieren Sie Ihre Fahrt!",
      "show": _vm.modal.active,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.saveTripType
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "isLoading": _vm.isSavingTripType
    },
    on: {
      "onModalClose": _vm.handleModalClose
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedTrip && "Markierne Sie die Fahrt von ".concat(_vm.selectedShift.driver.name, ". ")) + " "), _c('br'), _c('br'), _vm._v(" Zahlungsart "), _vm._l(_vm.tripTypes, function (tripType, index) {
    return _c('div', {
      key: index,
      staticClass: "RadioInput"
    }, [_c('input', {
      attrs: {
        "type": "radio",
        "id": tripType.key,
        "name": "tripTypeSelection"
      },
      domProps: {
        "value": tripType.key,
        "checked": _vm.tripTypeIsChecked(tripType)
      },
      on: {
        "click": _vm.handleTripTypeSelect
      }
    }), _c('label', {
      attrs: {
        "for": tripType.key
      }
    }, [_vm._v(_vm._s(tripType.label))])]);
  }), _c('div', [_c('br'), _c('label', {
    attrs: {
      "for": "paymentAmount"
    }
  }, [_vm._v("Erhaltener Betrag")]), _c('br'), _c('span', {
    staticClass: "Hint"
  }, [_vm._v(" Maximalbetrag: ")]), _c('span', {
    staticClass: "Hint"
  }, [_vm._v(_vm._s(_vm.selectedTrip ? "".concat(_vm.round2d(_vm.selectedTrip.totalAmount / 100).format(), "\u20AC") : ''))]), _c('br'), _c('Input', {
    attrs: {
      "type": "text",
      "pattern": "[0-9]+([,][0-9]+)?",
      "id": "paymentAmount",
      "name": "paymentAmount",
      "keyName": "paymentAmount",
      "value": _vm.wrongTripAmount
    },
    on: {
      "onKeyPress": _vm.handlePaymentAmountChange
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v(" Schnellwahl: ")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "5px"
    }
  }, [_c('span', {
    staticClass: "tag",
    on: {
      "click": function click($event) {
        _vm.wrongTripAmount = 0;
      }
    }
  }, [_vm._v("0 €")]), _c('span', {
    staticClass: "tag",
    on: {
      "click": function click($event) {
        _vm.wrongTripAmount = _vm.round2d(_vm.selectedTrip.totalAmount / 100).format();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedTrip ? "".concat(_vm.round2d(_vm.selectedTrip.totalAmount / 100).format(), "\u20AC") : '') + " ")])])], 1), _c('br'), _c('br')], 2), _c('Modal', {
    attrs: {
      "title": "Fahrer ändern",
      "show": _vm.showDriverChangeModal,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleDriverChange
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "isLoading": _vm.isChangingDriver
    },
    on: {
      "onModalClose": function onModalClose($event) {
        _vm.showDriverChangeModal = false;
      }
    }
  }, [_c('p', [_vm._v(" Neuen Fahrer für Schicht auswählen: ")]), _c('br'), _c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "placeholder": "Fahrer",
      "selected": _vm.selectedDriver,
      "items": _vm.drivers.filter(function (d) {
        return d.isVisible;
      })
    },
    on: {
      "onItemSelect": _vm.handleDriverSelect
    }
  }), _c('br'), _c('br')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }