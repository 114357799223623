var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Row-Wrap",
    class: [{
      'is-empty': _vm.mShifts.length === 0
    }]
  }, [_vm.mShifts.length > 0 ? _c('Row', {
    key: -1,
    staticClass: "Row-Header",
    attrs: {
      "parentHover": false,
      "items": {
        row: _vm.header
      },
      "index": -1
    },
    on: {
      "onClick": function onClick(args) {
        return _vm.$emit('onHeaderClick', args);
      },
      "onClickColumn": _vm.handleHeaderClick
    }
  }) : _vm._e(), _vm._l(_vm.mShifts, function (shift, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "items": shift,
        "index": i,
        "isInitialActive": _vm.showAllTrips,
        "isDropdown": shift.children && shift.children.length > 0,
        "hasWarning": _vm.hasWarning(shift),
        "hasError": shift.hasError
      },
      on: {
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        },
        "onChildClick": function onChildClick(_ref) {
          var index = _ref.index;
          return _vm.$emit('onTripClick', {
            i: i,
            j: index
          });
        }
      }
    });
  }), _vm.mShifts.length === 0 ? _c('EmptyState') : _vm._e(), _vm.mShifts.length >= 30 && _vm.mShifts.length < _vm.shifts.length ? _c('Button', {
    on: {
      "onClick": _vm.handleLoadMore
    }
  }, [_vm._v("Mehr Laden")]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }