<template>
    <div class="Shifts" :class="{ 'is-loading': isFetchingData }">
        <Headline style="margin-bottom: 50px">
            <h1 style="margin-bottom: 10px">
                Es gibt aktuell <span> {{ shiftCount }} Schichten</span>
            </h1>
            <h3>Gesamtumsatz der angezeigten Schichten: {{ shiftSum }}</h3>
            <br />
            <p style="margin: 20px 0 0; font-size: 16px;">
                Gesamtzahl der Zuschläge: {{ finalExtrasAmountCount.count }}
                <br />
                Gesamsumme der Zuschläge:
                {{ round2d(finalExtrasAmountCount.amount / 100).format() }}€
            </p>
        </Headline>
        <!-- <Infobox
            type="info"
            title="Information"
            message="Differenzen zwischen Schichtsummen und Einzelwerten in den Kilometerangaben entstehen durch Fahrten, bei denen kein Fahrer angemeldet ist. Um diese Differenzen zu minimieren, sollten Sie Ihren Fahrern nahelegen, keine unangemeldeten Fahrten zu tätigen."
        /> -->
        <Toolbar>
            <div>
                <Dropdown
                    hasSearch
                    placeholder="Fahrer"
                    :selected="selectedEmployee"
                    :items="drivers.filter(d => d.isVisible)"
                    @onItemSelect="handleEmployeeSelect"
                />
                <Dropdown
                    hasSearch
                    :selected="selectedCar"
                    :items="cars"
                    placeholder="Kennzeichen"
                    @onItemSelect="handleCarSelect"
                />

                <Datepicker
                    @onChange="handleDateChange"
                    :startDate="selectedFrom"
                    :endDate="selectedTo"
                />
                <Button @onClick="handleQuery" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
            </div>
            <div>
                <!-- <Download
                    :title="isMobile ? 'CSV' : 'Download CSV'"
                    type="csv"
                    @onDownload="handleDownload"
                /> -->
                <Download
                    :title="isMobile ? 'PDF' : 'Download PDF'"
                    type="pdf"
                    @onDownload="handleDownload"
                />
            </div>
        </Toolbar>
        <Toolbar>
            <div style="display: flex; gap:20px">
                <Checkbox
                    size="small"
                    keyName="includeWrongTrips"
                    name="includeWrongTrips"
                    :value="includeWrongTrips"
                    :direction="isMobile ? 'vertical' : 'horizontal'"
                    @onCheck="handleIncludeWrongTrips"
                >
                    Filter: Falschanmeldungen
                </Checkbox>
                <!-- <Checkbox
                    size="small"
                    keyName="includeFailedTrips"
                    name="includeFailedTrips"
                    :value="includeFailedTrips"
                    :direction="isMobile ? 'vertical' : 'horizontal'"
                    @onCheck="handleIncludeFailedTrips"
                >
                    Filter: Fehlfahrten
                </Checkbox> -->
                <Checkbox
                    size="small"
                    keyName="showAllTrips"
                    name="showAllTrips"
                    :value="showAllTrips"
                    :direction="isMobile ? 'vertical' : 'horizontal'"
                    @onCheck="handleShowAllTrips"
                >
                    Alle Fahrten anzeigen
                </Checkbox>
            </div>
        </Toolbar>

        <ShiftsTable
            :shifts="filteredShifts || shifts"
            :showAllTrips="showAllTrips"
            @onRowHover="handleRowHover"
            @onDriverClick="handleDriverClick"
            @onRowLeave="handleRowLeave"
            @onTripClick="handleTripClick"
            @onHeaderClick="handleHeaderClick"
        />
        <Modal
            title="Markieren Sie Ihre Fahrt!"
            :show="modal.active"
            :action="{ text: 'Speichern', color: 'green', callback: saveTripType }"
            :cancel="{ text: 'Abbrechen' }"
            :isLoading="isSavingTripType"
            @onModalClose="handleModalClose"
        >
            {{ selectedTrip && `Markierne Sie die Fahrt von ${selectedShift.driver.name}. ` }}
            <br />
            <br />
            Zahlungsart
            <div v-for="(tripType, index) in tripTypes" :key="index" class="RadioInput">
                <input
                    type="radio"
                    :id="tripType.key"
                    :value="tripType.key"
                    :checked="tripTypeIsChecked(tripType)"
                    @click="handleTripTypeSelect"
                    name="tripTypeSelection"
                />
                <label :for="tripType.key">{{ tripType.label }}</label>
            </div>

            <div>
                <br />
                <label for="paymentAmount">Erhaltener Betrag</label>
                <br />
                <span class="Hint"> Maximalbetrag: </span>
                <span class="Hint">{{
                    selectedTrip ? `${round2d(selectedTrip.totalAmount / 100).format()}€` : ''
                }}</span>
                <br />
                <Input
                    type="text"
                    pattern="[0-9]+([,][0-9]+)?"
                    id="paymentAmount"
                    name="paymentAmount"
                    keyName="paymentAmount"
                    :value="wrongTripAmount"
                    @onKeyPress="handlePaymentAmountChange"
                />
                <span class="Hint"> Schnellwahl: </span>
                <div style="display: flex; gap: 5px; ">
                    <span class="tag" @click="wrongTripAmount = 0">0 €</span>
                    <span
                        class="tag"
                        @click="wrongTripAmount = round2d(selectedTrip.totalAmount / 100).format()"
                    >
                        {{
                            selectedTrip
                                ? `${round2d(selectedTrip.totalAmount / 100).format()}€`
                                : ''
                        }}
                    </span>
                </div>
            </div>
            <br />
            <br />
        </Modal>

        <Modal
            title="Fahrer ändern"
            :show="showDriverChangeModal"
            :action="{ text: 'Speichern', color: 'green', callback: handleDriverChange }"
            :cancel="{ text: 'Abbrechen' }"
            :isLoading="isChangingDriver"
            @onModalClose="showDriverChangeModal = false"
        >
            <p>
                Neuen Fahrer für Schicht auswählen:
            </p>
            <br />
            <Dropdown
                hasSearch
                placeholder="Fahrer"
                :selected="selectedDriver"
                :items="drivers.filter(d => d.isVisible)"
                @onItemSelect="handleDriverSelect"
            />
            <br />
            <br />
        </Modal>
    </div>
</template>

<script>
import axios from 'axios';
import { startOfDay, endOfDay, subDays, startOfMonth, formatISO, endOfMonth } from 'date-fns';
import { round2d } from '@/lib/helper';

import Datepicker from '@/components/widgets/Datepicker';
import Input from '@/components/widgets/Input';
import Button from '@/components/widgets/Button';
import Download from '@/components/widgets/Download';
import Checkbox from '@/components/widgets/Checkbox';
import Dropdown from '@/components/widgets/Dropdown';
import Headline from '@/components/Headline';
// import Infobox from '@/components/widgets/Infobox';
import Modal from '@/components/widgets/Modal';
import Toolbar from '@/components/Toolbar';

import ShiftsTable from './components/ShiftsTable';

export default {
    name: 'Shifts',
    components: {
        ShiftsTable,
        Datepicker,
        Button,
        Download,
        Dropdown,
        Checkbox,
        Input,
        Headline,
        // Infobox,
        Modal,
        Toolbar,
    },
    data() {
        return {
            round2d,
            includeWrongTrips: false,
            includeFailedTrips: false,
            showAllTrips: false,
            shiftSum: 0,
            wrongTripAmount: 0,
            showDriverChangeModal: false,
            selectedDriver: null,
            isFetchingData: false,
            isChangingDriver: false,
            isSavingTripType: false,
            activeRow: -1,
            activeBar: -1,
            shifts: [],
            drivers: JSON.parse(localStorage.getItem('drivers')),
            cars: JSON.parse(localStorage.getItem('cars')),
            shiftIndex: null,
            tripIndex: null,
            tripTypes: [
                { label: 'Barzahlung', key: 'cash' },
                { label: 'FreeNow', key: 'freenow' },
                { label: 'Coupon', key: 'coupon' },
                { label: 'taxi.eu Payment', key: 'taxieupayment' },
                { label: 'Kartenzahlung', key: 'card' },
                { label: 'Rechnung', key: 'invoice' },
            ],
            filteredShifts: [],
            selectedEmployee: null,
            selectedCar: null,
            selectedFrom: startOfMonth(new Date()),
            selectedTo: endOfDay(new Date()),
            selectedShift: null,
            selectedTrip: null,
            selectedTripType: null,
            modal: {
                active: false,
            },
        };
    },
    computed: {
        isMobile() {
            return window.innerWidth < 500;
        },
        shiftCount() {
            return this.shifts.length;
        },
        finalExtrasAmountCount() {
            return this.shifts.reduce(
                (acc, shift) => {
                    const { count, amount } = shift.trips.reduce(
                        (tripAcc, trip) => {
                            const finalExtrasAmount = Number(trip.finalExtrasAmount);
                            return {
                                count: tripAcc.count + (finalExtrasAmount > 0 ? 1 : 0),
                                amount: tripAcc.amount + finalExtrasAmount,
                            };
                        },
                        { count: 0, amount: 0 },
                    );

                    return {
                        count: acc.count + count,
                        amount: acc.amount + amount,
                    };
                },
                { count: 0, amount: 0 },
            );
        },
    },
    methods: {
        handleShowAllTrips(checked) {
            this.showAllTrips = checked;
            this.filteredShifts = [...this.shifts];
        },
        handleIncludeFailedTrips(checked) {
            this.includeFailedTrips = checked;
            const url = new URL(window.location.href);
            url.searchParams.delete('incorrect');
            url.searchParams.delete('startDate');
            const hasWarning = entry => {
                return entry?.subtractedTotalAmount !== 0 || !entry.driver?.name;
            };
            window.history.replaceState({}, '', url);
            this.filteredShifts = checked ? this.shifts.filter(s => hasWarning(s)) : this.shifts;
        },
        handleIncludeWrongTrips(checked) {
            this.includeWrongTrips = checked;

            const url = new URL(window.location.href);
            url.searchParams.delete('incorrect');
            url.searchParams.delete('startDate');

            window.history.replaceState({}, '', url);
            this.filteredShifts = checked
                ? this.shifts.filter(s => s.driver.driverNumber == s.driver.name)
                : this.shifts;
        },
        handlePaymentAmountChange({ paymentAmount }) {
            const value = paymentAmount.replace(/[^0-9,]/g, '');
            this.wrongTripAmount = value;
        },

        handleTripTypeSelect({ target: { value } }) {
            this.selectedTripType = value;
        },
        setTrips(trips) {
            return trips || [];
        },
        setActiveBar(i) {
            this.activeBar = i;
        },
        dataset() {
            let arr = [];
            for (var i = 0; i < 31; i++) {
                arr.push(Math.floor(Math.random() * 400));
            }
            return arr;
        },
        handleModalClose() {
            this.resetTripSelection();
            this.modal.active = false;
        },
        handleHeaderClick(props) {},
        handleTripClick({ i: shiftIndex, j: tripIndex }) {
            this.selectedShift = this.shifts[shiftIndex];
            const driverName = this.selectedShift?.driver?.name;
            this.selectedTrip = {
                ...this.selectedShift.trips[tripIndex - 1],
                driver: { name: driverName },
            };
            this.wrongTripAmount = this.selectedTrip.finalTotalAmount
                ? round2d(this.selectedTrip.finalTotalAmount / 100).format()
                : 0;
            this.modal.active = true;
        },
        handleEmployeeSelect({ item }) {
            this.selectedEmployee = item;
        },
        handleCarSelect({ item }) {
            this.selectedCar = item;
        },
        async saveTripType() {
            try {
                console.log('Saving trip type', this.selectedTrip);
                this.isSavingTripType = true;
                const tripId = this.selectedTrip.uuid;
                const paymentMethod = this.selectedTripType || this.selectedTrip.paymentMethod;
                console.log('Payment method', paymentMethod);
                const newAmount = Math.round(
                    Number(String(this.wrongTripAmount).replace(',', '.')) * 100,
                );

                if (newAmount < 0) {
                    this.$toasted.show('Der Betrag darf nicht negativ sein.', { type: 'error' });
                    return;
                }

                if (newAmount !== this.selectedTrip.finalTotalAmount) {
                    if (newAmount > this.selectedTrip.totalAmount) {
                        this.$toasted.show(
                            'Der Betrag darf nicht höher sein als der Gesamtbetrag.',
                            { type: 'error' },
                        );
                        return;
                    }
                    const urlPaymentAmount = `${process.env.VUE_APP_API_BASE_URL}/cpanel/trips/${tripId}/payment_amount`;
                    await axios.patch(
                        urlPaymentAmount,
                        { paymentAmount: newAmount },
                        { withCredentials: true },
                    );
                }

                if (paymentMethod !== 'wrong') {
                    const urlPaymentMethod = `${process.env.VUE_APP_API_BASE_URL}/cpanel/trips/${tripId}/payment_method`;
                    await axios.patch(
                        urlPaymentMethod,
                        { paymentMethod },
                        { withCredentials: true },
                    );
                }

                this.resetTripSelection();
            } catch (error) {
                console.error(error);
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isSavingTripType = false;
                this.modal.active = false;
                this.handleQuery();
            }
        },
        resetTripSelection() {
            this.selectedShift = null;
            this.selectedTrip = null;
            this.selectedTripType = null;
        },
        handleDriverSelect({ item }) {
            this.selectedDriver = item;
        },
        handleDownload(type) {
            const url = `https://api.app.taxifusion.de/cpanel/shifts/${this.selectedFrom.getFullYear()}/${this.selectedFrom.getMonth() +
                1}/driver/${this.selectedEmployee ? this.selectedEmployee.id : 0}/license/${
                this.selectedCar ? this.selectedCar.id : 0
            }/${type}`;
            window.open(url, '_blank');
        },
        handleDateChange(dateRange) {
            this.selectedFrom = dateRange.from || this.selectedFrom || startOfMonth(new Date());
            this.selectedTo = dateRange.to || this.selectedFrom;
        },
        handleRowHover(index) {
            this.activeRow = index;
        },
        handleRowLeave() {
            this.activeRow = -1;
        },
        handleDriverClick(id) {
            this.selectedShift = this.shifts.find(s => s.uuid === id);
            this.showDriverChangeModal = true;
        },
        tripTypeIsChecked(tripType) {
            if (!this.selectedTrip) return false;

            return tripType.key === this.selectedTrip.paymentMethod;
        },
        async handleDriverChange() {
            this.isChangingDriver = true;
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/shifts/${this.selectedShift.uuid}/driver`;
            const res = await axios
                .patch(url, { driverNumber: this.selectedDriver.id }, { withCredentials: true })
                .catch(() => {
                    this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
                })
                .finally(() => {
                    this.isChangingDriver = false;
                });

            this.$toasted.show('Fahrer erfolgreich geändert.', { type: 'success' });
            this.showDriverChangeModal = false;
            this.handleQuery();
        },
        async handleQuery() {
            this.isFetchingData = true;
            const queryData = {
                driverNumber: this.selectedEmployee ? this.selectedEmployee.id : null,
                licenseNumber: this.selectedCar ? this.selectedCar.id : null,
                rangeStartAt: formatISO(this.selectedFrom),
                rangeEndAt: this.selectedTo ? formatISO(endOfDay(this.selectedTo)) : null,
            };
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/shifts`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });
                this.shiftSum = result.data.reduce(
                    (acc, { finalTotalAmount }) => (acc += finalTotalAmount),
                    0,
                );
                this.$emit('onFinishLoading');
                this.shiftSum = `${round2d(this.shiftSum / 100).format()}€`;
                this.shifts = result.data;

                this.filteredShifts = this.includeWrongTrips
                    ? this.shifts.filter(s => s.driver.driverNumber == s.driver.name)
                    : this.shifts;
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
    },
    mounted() {
        // get query params
        const urlParams = new URLSearchParams(window.location.search);
        const incorrect = urlParams.get('incorrect');
        const startDate = urlParams.get('startDate');
        const driverNumber = urlParams.get('driverNumber');
        const licenseNumber = urlParams.get('licenseNumber');
        this.includeWrongTrips = incorrect === 'true';
        this.selectedFrom = startDate ? new Date(startDate) : startOfMonth(new Date());
        this.selectedTo = startDate ? endOfMonth(new Date(startDate)) : endOfDay(new Date());
        this.selectedEmployee = this.drivers.find(d => d.id == driverNumber);
        this.selectedCar = this.cars.find(c => c.id == licenseNumber);

        setTimeout(() => {
            this.handleQuery();
        }, 300);
    },
};
</script>

<style lang="scss">
.Shifts {
    @extend %contentWrapper;
    @extend %page;

    .RadioInput {
        font-size: 1rem;
        padding: 6px 0;
        margin-left: 20px;
        display: flex;
        align-items: center;
        gap: 8px;

        input {
            margin: 0;
        }
    }

    .tag {
        border: 1px solid color(blue-light);
        border-color: color(blue-light);
        background-color: rgba(0, 0, 0, 0);
        color: color(blue-dark);
        padding: 2px 6px;
        border-radius: 40px;
        cursor: pointer;
        font-size: 14px;
        min-width: 50px;
        display: inline-block;
        text-align: center;

        &:hover {
            border-color: color(blue-dark);
            background-color: rgba(0, 0, 0, 0.1);
            color: color(blue-light);
        }
    }
}
</style>
